import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../tailwind.config';

export default {
  data() {
    return {
      currentBreakpoint: null
    };
  },
  mounted() {
    this.getActiveBreakpoint();
    window.addEventListener('resize', this.getActiveBreakpoint);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getActiveBreakpoint);
  },
  methods: {
    getActiveBreakpoint() {
      const breakpoints = resolveConfig(tailwindConfig).theme.screens;

      for (const breakpoint in breakpoints) {
        const minWidth = breakpoints[breakpoint];
        const mediaQuery = breakpoint === 'sm'
          ? `(min-width: 0px), (max-width: ${minWidth}`
          : `(min-width: ${minWidth})`;

        if (window.matchMedia(mediaQuery).matches) {
          this.currentBreakpoint = breakpoint;
        }
      }
    }
  }
};
