const fluid = ($minFontSize, $maxFontSize, $minVw, $maxVw) => {
  if ($minVw === undefined) {
    $minVw = 640;
  }
  if ($maxVw === undefined) {
    $maxVw = 1536;
  }

  if ($minFontSize !== undefined && $maxFontSize === undefined) {
    $maxFontSize = $minFontSize;
  }

  const rise = $maxFontSize - $minFontSize;
  const run = $maxVw - $minVw;
  const slope = rise / run;
  const fluidValue = `calc(${slope} * (100vw - ${$minVw}px) + ${$minFontSize}px)`;

  const minFontSizeInRem = $minFontSize / 16;
  const maxFontSizeInRem = $maxFontSize / 16;
  return `clamp(${minFontSizeInRem}rem,${fluidValue},${maxFontSizeInRem}rem);`;
};

const smScreenSize = 640;
const mdScreenSize = 768;
const lgScreenSize = 1024;
const xlScreenSize = 1280;
const xxlScreenSize = 1536;

module.exports = {
  mode: 'jit',
  darkMode: false, // or 'media' or 'class'
  theme: {
    screens: {
      sm: `${smScreenSize}px`,
      // => @media (min-width: 640px) { ... }

      md: `${mdScreenSize}px`,
      // => @media (min-width: 768px) { ... }

      lg: `${lgScreenSize}px`,
      // => @media (min-width: 1024px) { ... }

      xl: `${xlScreenSize}px`,
      // => @media (min-width: 1280px) { ... }

      '2xl': `${xxlScreenSize}px`
      // => @media (min-width: 1536px) { ... }
    },
    fontFamily: {
      // Add fonts
      sans: ['Gilroy', 'sans-serif']
    },
    grid: {
      gridXMargin: fluid(24, 95, smScreenSize, xxlScreenSize),
      gutterXDesktop: '48px',
      gutterXMobile: '16px',
      gutterYDesktop: '48px',
      gutterYMobile: '30px'
    },
    spacing: {
      /* These values are to be used for vertical spacing mainly
       By default, these values are inherited by the:
       padding, margin, width, height, maxHeight, gap, inset, space, and translate core plugins. */

      0: '0', // 0px
      1: '0.5rem', // 8px
      2: '1rem', // 16px
      3: '1.5rem', // 24px
      4: '2rem', // 32px
      5: '2.5rem', // 40px
      6: '3rem', // 48px
      7: '3.5rem', // 56px
      8: '4rem', // 64px
      9: '5rem', // 80px
      10: '6rem', // 96px
      11: '7rem' // 112px
    },
    extend: {
      colors: {
        // Add project colors
        main: '#2FB292',
        secondary: '#009074',
        graydark: '#191919',
        grayLight: '#F6F6F6',
        grayUltraLight: '#CDCDCD'
      }
    }
  },
  variants: {
    extend: {}
  },
  plugins: [
    require('tailwindcss-selection-variant'),
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/typography')
  ]
  // corePlugins: {
  //   container: false
  // }
};
