
import SVGCertificate from '~/assets/svg/certificate.svg';
export default {
  components: { SVGCertificate },
  props: {
    data: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    certificateLink() {
      return this.getPageByRef(this.data.certificateButton?.link?._ref)?.url?.fullPath;
    }
  }
};
